import Image from "next/image";
import { WINT_LOGO } from "./logo";
type WintLogoType = {
  width?: number;
  height?: number;
};
export const WintLogoLight: React.FC<WintLogoType> = ({
  width = 102,
  height = 24
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_LIGHT} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoLight" data-sentry-source-file="index.tsx" />;
};
export const WintLogoDark: React.FC<WintLogoType> = ({
  width = 102,
  height = 24
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_DARK} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoDark" data-sentry-source-file="index.tsx" />;
};
export const WintLogoDarkMono: React.FC<WintLogoType> = ({
  width = 102,
  height = 24
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_DARK_MONO} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoDarkMono" data-sentry-source-file="index.tsx" />;
};
export const WintLogoLightMono: React.FC<WintLogoType> = ({
  width = 102,
  height = 24
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_LIGHT_MONO} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoLightMono" data-sentry-source-file="index.tsx" />;
};
export const WintLogoLightSymbol: React.FC<WintLogoType> = ({
  width = 32,
  height = 32
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_LIGHT_SYMBOL} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoLightSymbol" data-sentry-source-file="index.tsx" />;
};
export const WintLogoDarkSymbol: React.FC<WintLogoType> = ({
  width = 32,
  height = 32
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_DARK_SYMBOL} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoDarkSymbol" data-sentry-source-file="index.tsx" />;
};
export const WintLogoBlackSymbol: React.FC<WintLogoType> = ({
  width = 32,
  height = 32
}) => {
  return <Image src={WINT_LOGO.WINT_LOGO_BLACK_SYMBOL} width={width} height={height} alt="wint logo" data-sentry-element="Image" data-sentry-component="WintLogoBlackSymbol" data-sentry-source-file="index.tsx" />;
};