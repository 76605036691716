globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5e384e100b581d4e20de15cc00c294b519a5af92"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/wb";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

Sentry.init({
	dsn: "https://960a87fe4e7196dd9f59d0ae23625f5a@o4508007956611072.ingest.us.sentry.io/4508121425969152",
	environment: process.env.NEXT_PUBLIC_ENV_VAR,
	// Add optional integrations for additional features
	integrations: [Sentry.replayIntegration()],

	// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
	tracesSampleRate: Number(process.env.NEXT_PUBLIC_SETNRY_TRACE_SAMPLE),

	// Define how likely Replay events are sampled.
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.05,

	// Define how likely Replay events are sampled when an error occurs.
	replaysOnErrorSampleRate: 0.1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,
})
