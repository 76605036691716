"use client";

import Image from "next/image";
import styled from "styled-components";
const MaintananceScreen = () => {
  const imagesCdnUrl = "https://d1c9xn9tvapvue.cloudfront.net";
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="MaintananceScreen" data-sentry-source-file="index.tsx">
            <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx">
                <Image src={`${imagesCdnUrl}/webDS/icons/mainLogo/WintLogoLight.svg`} width={180} height={39} alt="logo" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </Logo>

            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <Image priority width={476} height={489} src={`${imagesCdnUrl}/maintainence/Maintenance-img.svg`} alt="maintainence-screen" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                <Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
                    <Badge data-sentry-element="Badge" data-sentry-source-file="index.tsx">The website will be offline for 2 hours.</Badge>
                    <h1>Housekeeping in progress</h1>
                    <p>
                        To keep your experience seamless, our
                        <span> website</span>
                        is going through
                        <b> maintenance.</b>
                    </p>
                </Content>
            </Container>
            <FooterContent data-sentry-element="FooterContent" data-sentry-source-file="index.tsx">
                Come back again in some time and gain access to your Wint Wealth
                experience.
            </FooterContent>
        </Wrapper>;
};
export default MaintananceScreen;
const Wrapper = styled.div`
  width: 1366px;
  margin: auto;
  position: relative;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;
const Logo = styled.div`
  width: 180px;
  margin-top: 2rem;
  @media (max-width: 1080px) {
    display: block;
    margin: 2rem auto;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5rem;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1080px) {
    flex-direction: column-reverse;
  }

  img {
    margin-top: 10rem;
    @media (max-width: 1080px) {
      width: 80%;
      margin: 0;
    }
  }
`;
const Content = styled.div`
  width: 35%;
  margin-right: 4rem;
  @media (max-width: 1080px) {
    width: 100%;
    text-align: center;
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 58px;
    @media (max-width: 1080px) {
      font-size: 28px;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #1f1f1f;
    opacity: 0.6;
    @media (max-width: 1080px) {
      width: 80%;
      margin: 1rem auto 2rem auto;
    }
  }
`;
const Badge = styled.div`
  font-size: 12px;
  width: 100%;
  margin: auto;
  background: #b6cfb7;
  padding: 5px;
  margin-bottom: 1rem;
  text-align: center;
  @media (max-width: 1080px) {
    font-size: 12px;
    width: 80%;
    margin: auto;
  }
`;
const FooterContent = styled.div`
  position: absolute;
  right: 20px;
  font-size: 20px;
  color: #356935;
  font-weight: 600;
  bottom: 0;
  background: white;
  text-align: center;
  @media (max-width: 1080px) {
    font-size: 14px;
    right: auto;
    bottom: 5%;
  }
`;