import { Children, createContext, useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled, { css, useTheme } from "styled-components";
import { ChevronDownIcon } from "wint-icons";
import { minDesktopCss } from "@common/styles/media";
import { BodyMediumText, HeadingSansSerifSmallText } from "@common/styles/typographies";
const show = {
  collapsed: {
    opacity: 0,
    height: 0
  },
  open: {
    opacity: 1,
    height: "auto",
    transition: {
      height: {
        duration: 0.3
      },
      opacity: {
        duration: 0.25,
        delay: 0.15
      }
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3
      },
      opacity: {
        duration: 0.25
      }
    }
  }
};
type DefaultAccordionContext = {
  isOpen: boolean;
  index: number;
  onChangeIndex: Function;
};
type AccordionProps = {
  children: React.ReactNode;
  defaultIndex?: number;
};
const AccordionContext = createContext<DefaultAccordionContext>({
  isOpen: false,
  index: 0,
  onChangeIndex: () => {}
});
const useAccordion = () => useContext(AccordionContext);
export const Accordion: React.FC<AccordionProps> = ({
  children,
  defaultIndex
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const onChangeIndex = (index: number) => {
    setActiveIndex(() => {
      return index === activeIndex ? -1 : index;
    });
  };
  return Children.map(children, (child, index) => {
    const isOpen = activeIndex === index;
    return <AccordionContext.Provider value={{
      isOpen,
      index,
      onChangeIndex
    }}>
				{child}
			</AccordionContext.Provider>;
  });
};
type AccordionItemProps = {
  leadingIcon?: any;
  title: any;
  parseHTML?: boolean;
  content: any;
  isLastItem?: boolean;
  clickId?: string;
  onClick?: () => void;
};
const AccordionItem: React.FC<AccordionItemProps> = ({
  leadingIcon,
  title,
  parseHTML,
  content,
  isLastItem,
  clickId,
  onClick
}) => {
  const {
    isOpen,
    index,
    onChangeIndex
  } = useAccordion();
  const theme = useTheme();
  const handleAccordionToggle = () => {
    if (onClick) {
      onClick();
    }
    onChangeIndex(index);
  };
  return <AccordionWrapper data-sentry-element="AccordionWrapper" data-sentry-component="AccordionItem" data-sentry-source-file="AccordionItem.tsx">
			{leadingIcon || null}
			<AccordionDetails id={clickId} $isLastItem={isLastItem} onClick={handleAccordionToggle} $isOpen={isOpen} data-sentry-element="AccordionDetails" data-sentry-source-file="AccordionItem.tsx">
				{title ? <AccordionTitle id={clickId}>
						<HeadingSansSerifSmallText id={clickId} $mobColor={theme?.colors.text.secondary} $webColor={theme?.colors.text.secondary}>
							{title}
						</HeadingSansSerifSmallText>
						<ChevronIcon id={clickId} $isOpen={isOpen} />
					</AccordionTitle> : null}
				<AnimatePresence initial={false} mode="wait" onExitComplete={() => null} data-sentry-element="AnimatePresence" data-sentry-source-file="AccordionItem.tsx">
					{content && isOpen ? <motion.div initial="collapsed" animate="open" exit="exit" variants={show}>
							<AccordionContent id={clickId}>
								{parseHTML ? <BodyMediumText id={clickId} $mobColor={theme?.colors.text.tertiary} $webColor={theme?.colors.text.tertiary} dangerouslySetInnerHTML={{
              __html: content
            }} /> : <BodyMediumText id={clickId} $mobColor={theme?.colors.text.tertiary} $webColor={theme?.colors.text.tertiary}>
										{content}
									</BodyMediumText>}
							</AccordionContent>
						</motion.div> : null}
				</AnimatePresence>
			</AccordionDetails>
		</AccordionWrapper>;
};
export default AccordionItem;
const AccordionWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 12px;

	${minDesktopCss(css`
		gap: 1rem;
	`)}
`;
const AccordionDetails = styled.div<{
  $isOpen: boolean;
  $isLastItem?: boolean;
}>`
	padding: 0rem 0rem 1.5rem;
	border-bottom: ${({
  $isLastItem,
  theme
}) => $isLastItem ? "" : `1px solid ${theme.colors.stroke.card.divider}`};
	flex: 1;
	cursor: pointer;
`;
const AccordionTitle = styled.summary`
	display: flex;
	align-items: center;
	justify-content: space-between;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;
const AccordionContent = styled.div`
	padding: 1rem 0rem 0rem; // Extra inner div needed for padding to handle animation with framer-motion
`;
const ChevronIcon = styled(ChevronDownIcon)<{
  $isOpen: boolean;
}>`
	max-width: 1rem;
	max-height: 1rem;
	color: ${({
  $isOpen,
  theme
}) => $isOpen ? theme.colors.text.primary : theme.colors.text.tertiary};
	rotate: ${({
  $isOpen
}) => $isOpen ? "180deg" : ""};
	transition: all ease 0.5s;
`;