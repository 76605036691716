import styled, { Interpolation, css } from "styled-components";
import { motion } from "framer-motion";
import { minDesktopCss } from "@common/styles/media";
type ButtonProps = {
  type?: "primary" | "secondary" | "tertiary";
  size?: "large" | "medium";
  spacing?: "center" | "space-between";
  themeInverse?: boolean;
  text?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  leftIcon?: any;
  icon?: any;
  mobileStyles?: Interpolation<React.CSSProperties>;
  desktopStyles?: Interpolation<React.CSSProperties>;
  clickId?: string;
  buttonTapScale?: number;
};
const Button: React.FC<ButtonProps> = ({
  text,
  type,
  size,
  leftIcon,
  icon,
  spacing,
  themeInverse,
  mobileStyles,
  desktopStyles,
  disabled,
  onClick,
  clickId,
  buttonTapScale = 0.95
}) => {
  return <StyledButton whileTap={{
    scale: buttonTapScale
  }} $type={type} $size={size} $spacing={spacing} $themeInverse={themeInverse} $mobileStyles={mobileStyles} $desktopStyles={desktopStyles} disabled={disabled} onClick={onClick} id={clickId} data-sentry-element="StyledButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
			{leftIcon || null}
			{text || null}
			{icon || null}
		</StyledButton>;
};
export default Button;
const StyledButton = styled(motion.button)<{
  $type?: string;
  $size?: string;
  $spacing?: string;
  $themeInverse?: boolean;
  $mobileStyles?: Interpolation<React.CSSProperties>;
  $desktopStyles?: Interpolation<React.CSSProperties>;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 4px;
	width: 100%;
	flex: 1 0 0;
	cursor: pointer;
	transition: background-color 0.2s ease-in;

	&:disabled {
		background-color: ${({
  theme
}) => theme.colors.bg.button.primaryDisabled};
		cursor: not-allowed;
	}

	${({
  $type,
  $themeInverse
}) => $type === "primary" && css`
			border: none;
			background-color: ${({
  theme
}) => $themeInverse ? theme.colors.bg.button.inverse : theme.colors.bg.button.primary};
			color: ${({
  theme
}) => $themeInverse ? theme.colors.text.button.onInverse : ""};

			&:hover:not([disabled]) {
				background-color: ${({
  theme
}) => $themeInverse ? theme.colors.bg.button.inversePrimaryHover : theme.colors.bg.button.primaryHover};
				transition: background-color 0.2s ease-out;
			}
		`}

	${({
  $type,
  $themeInverse
}) => $type === "secondary" && css`
			border: ${({
  theme
}) => $themeInverse ? `1px solid ${theme.colors.stroke.button.buttonInverse}` : `1px solid ${theme.colors.stroke.button.button}`};
			background-color: ${({
  theme
}) => $themeInverse ? "transparent" : theme.colors.bg.button.secondary};
			color: ${({
  theme
}) => $themeInverse ? theme.colors.text.button.onPrimary : ""};

			&:hover:not([disabled]) {
				background-color: ${({
  theme
}) => theme.colors.bg.button.secondaryHover};
				transition: background-color 0.2s ease-out;
			}
		`}

	${({
  $size
}) => $size === "large" && css`
			padding: 16px;
		`}

	${({
  $size
}) => $size === "medium" && css`
			padding: 12px;
		`}

    ${({
  $spacing
}) => $spacing === "center" && css`
			justify-content: center;
		`}

    ${({
  $spacing
}) => $spacing === "space-between" && css`
			justify-content: space-between;
		`}

		${({
  $type,
  $themeInverse
}) => $type === "tertiary" && css`
			border: none;
			background-color: transparent;
			color: ${({
  theme
}) => $themeInverse ? theme.colors.text.button.onPrimary : ""};
			padding: 0px;
		`}

		
		
	${({
  $mobileStyles
}) => css`
		${$mobileStyles}
	`}

	${({
  $desktopStyles
}) => minDesktopCss(css`
			${$desktopStyles}
		`)}
`;