import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ChevronRightIcon, ArrowRightIcon } from "wint-icons";
import { useAppContext } from "@common/context/AppContext";
import { SITE_URLS } from "@common/constants";
import LegacyButton from "@legacy/common/components/Button";
import { GridItemContainer } from "@common/styles";
import { getInitialsFromName } from "@common/utils/string";
import { WINT_LOGO } from "@common/components/MainLogo/logo";
import { getAppDownloadLink, getBottomNavBarItems, handleReferAndEarnClick } from "../../utils";
import { NAV_CTA_IDS, DOWNLOAD_APP_BANNER_SIDEBAR_DATA } from "../../constants";
import DownloadBanner from "../DownloadBanner";
import { BottomBarActions, DropdownSideBarItem, NavbarItem } from "../../types";
const LegacyButtonTyped: any = LegacyButton;
type BottomNavBarProps = {
  openLoginModal: () => void;
  handleLogout: () => void;
  checkIsUrlActive: (url: string) => boolean;
};
const BottomNavBar = ({
  openLoginModal,
  handleLogout,
  checkIsUrlActive
}: BottomNavBarProps) => {
  const SIDE_BAR_PAGE_KEYS = {
    param: "page",
    value: "more"
  };
  const {
    userState,
    isIOSDevice
  } = useAppContext();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const handleSidebarToggle = () => {
    setIsSidebarVisible(true);
    window.history.pushState({}, "", `?${SIDE_BAR_PAGE_KEYS.param}=${SIDE_BAR_PAGE_KEYS.value}`);
    document.body.style.overflow = "hidden";
  };
  const navBarActions: BottomBarActions = {
    sidebar: handleSidebarToggle,
    login: openLoginModal,
    logOut: handleLogout,
    referAndClick: () => handleReferAndEarnClick()
  };
  const navigationItems = getBottomNavBarItems(navBarActions, userState);
  const handleNavLinkClick = (e: any, item: NavbarItem) => {
    if (item?.action) {
      item.action && item.action();
      e.preventDefault();
    } else {
      window.location.href = item.link as string;
    }
  };
  const handleSideBarClick = (e: any, sidebarItem: DropdownSideBarItem) => {
    if (sidebarItem.action) {
      sidebarItem.action();
      e.stopPropagation();
    } else {
      window.location.href = sidebarItem.link as string;
      e.preventDefault();
    }
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pageParam = queryParams.get(SIDE_BAR_PAGE_KEYS.param);
    setIsSidebarVisible(pageParam === SIDE_BAR_PAGE_KEYS.value);
  }, [SIDE_BAR_PAGE_KEYS.param, SIDE_BAR_PAGE_KEYS.value]);
  return <NavbarContainer as="nav" $useGridPadding data-sentry-element="NavbarContainer" data-sentry-component="BottomNavBar" data-sentry-source-file="index.tsx">
			<NavList data-sentry-element="NavList" data-sentry-source-file="index.tsx">
				{/* regular bottombar items */}
				{navigationItems?.left.filter(items => items.isVisible).map((item: NavbarItem, index) => <NavItem key={index} id={item.id}>
							<NavLink onClick={e => handleNavLinkClick(e, item)}>
								<Items className={isSidebarVisible && item.label === "More" || !isSidebarVisible && checkIsUrlActive(item.link as string) ? "active" : ""}>
									{" "}
									<Icon alt={`icon_${index}`} src={item.icon} />
									{item.label}
								</Items>
								{/* sidebar render */}
								{item.sidebar && isSidebarVisible && <Sidebar id={isSidebarVisible ? "sidebar_show" : ""}>
										<Header onClick={() => {
              window.location.href = SITE_URLS.PROFILE;
            }} id={NAV_CTA_IDS.your_profile}>
											<Details>
												<Name>{userState.userName}</Name>
												<Profile>
													<div>Account Details</div>
													<ArrowRightIcon color="#7E7E7E" height={12} width={12} style={{
                    transform: "rotate(-45deg)"
                  }} />
												</Profile>
											</Details>
											<UserIcon>
												{getInitialsFromName(userState.userName)}
											</UserIcon>
										</Header>
										<SideBarItems>
											{item.sidebar?.filter(sidebarItem => sidebarItem.isVisible).map((sidebarItem: DropdownSideBarItem, idx) => <ItemWrapper id={sidebarItem.id} key={idx} onClick={e => handleSideBarClick(e, sidebarItem)}>
														<Icon padding="24px 0 24px 24px" alt={`icon_${idx}`} src={sidebarItem.icon} />

														<SidebarItem className={idx !== (item.sidebar?.length ?? 0) - 1 ? "border" : ""}>
															{sidebarItem.label}{" "}
															<ChevronRightIcon color="#7E7E7E" height="16px" />
														</SidebarItem>
													</ItemWrapper>)}
										</SideBarItems>
										<CardWrapper>
											<DownloadBanner clickId="NAV-DOWNLOAD_APP" title={DOWNLOAD_APP_BANNER_SIDEBAR_DATA.title} label="Download on" logo={WINT_LOGO.WINT_LOGO_LIGHT_SYMBOL} link={getAppDownloadLink(isIOSDevice)} />
										</CardWrapper>
									</Sidebar>}
							</NavLink>
						</NavItem>)}
			</NavList>
			{/* for logged-out state CTA */}
			{!userState?.isLoggedIn && <NavList>
					{navigationItems.right.filter(items => items.isVisible).map((item, index) => <NavItem key={index} id={item.id}>
								<NavLink id={item.id} onClick={e => {
          if (item.action) {
            item.action && item.action();
            e.preventDefault();
          }
        }}>
									<LegacyButtonTyped id="NAV_LOGIN" textColor="#fff" variant="black" text={item.label} fontSize="14px" width="148px" />
								</NavLink>
							</NavItem>)}
				</NavList>}
		</NavbarContainer>;
};
export default BottomNavBar;
const NavbarContainer = styled(GridItemContainer)`
	height: 68px;
	position: relative;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: white;
	border-top: 1px solid #f2f2f2;
	gap: 1rem;
	z-index: 99;
`;
const NavList = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	width: 100%;
	z-index: 99;
	height: 100%;
	align-items: center;
`;
const Items = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	font-family: var(--font-inter);
	font-size: 10px;
	font-style: normal;
	line-height: 16px;
	color: #7e7e7e;
	font-weight: 400;
	&.active {
		color: #000;
		font-weight: 500;
		filter: grayscale(100%) brightness(0%);
	}
`;
const NavItem = styled.li`
	position: relative;
	text-align: center;
	height: 100%;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
`;
const NavLink = styled.a`
	color: black;
	text-decoration: none;
	padding: 10px;
	display: block;
`;
const Sidebar = styled.div`
	z-index: -1;
	width: 100%;
	height: calc(100% - 64px);
	position: fixed;
	right: 0;
	top: 0;
	background-color: white;
	box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1);
`;
const SidebarItem = styled.div`
	position: relative;
	text-align: left;
	width: 100%;
	color: #000;
	padding: 24px 0;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	padding-right: 24px;
	&.border {
		border-bottom: 1px solid #e5e5e5;
	}
`;
const Header = styled.div`
	width: 100%;

	height: 100px;
	border-bottom: 1px solid #f2f2f2;
	background: #fafafa;
	padding: 24px;
	display: flex;
	justify-content: space-between;
`;
const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
`;
const Name = styled.div`
	color: #000;
	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px;
`;
const Profile = styled(motion.div).attrs({
  whileTap: {
    scale: 0.9
  }
})`
	color: #7e7e7e;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 4px;
`;
const UserIcon = styled.div`
	border-radius: 4px;
	background: #172557;
	width: 52px;
	height: 52px;
	color: #fff;
	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const SideBarItems = styled.div`
	padding: 8px 0;
`;
const ItemWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
	&:hover {
		background: #fafafa;
		z-index: 2;
		width: 100%;
	}
`;
const Icon = styled.img<{
  padding?: string;
}>`
	padding: ${({
  padding
}) => padding || 0};
`;
const CardWrapper = styled.div`
	padding: 20px;
	span {
		display: flex;
		align-items: center;
		gap: 2px;
	}
`;