import { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { ArrowLeft24StrokedIcon } from "wint-icons";
import { useRouter, useSearchParams } from "next/navigation";
import { useAppContext } from "@common/context/AppContext";
import { getRedirectLinkBasedonInvestorStatus } from "@common/utils/url";
import { SITE_URLS } from "@common/constants";
import { Flex, FlexRow, GridItemContainer } from "@common/styles";
import { getFromSessionStorage, isIOSWebview, isWebview } from "@common/utils/browser";
import { isUndefinedOrNull } from "@common/utils/variable";
import { BodyMediumText, LabelSemiBoldText } from "@common/styles/typographies";
import { motion } from "framer-motion";
import { ORDERS_PAGE_CLICK_IDS } from "@features/Orders/Utils";
type BackMobNavigationProps = {
  isFixed?: boolean;
  margin?: string;
  showBorder?: boolean;
  path?: string;
};
const BackMobNavigation = ({
  isFixed = true,
  margin = "",
  showBorder = true,
  path = ""
}: BackMobNavigationProps) => {
  const router = useRouter();
  const theme = useTheme();
  const {
    isDesktop,
    ovverrideBackNavLink,
    navbarBackButtonTitle,
    showNavbarBackButtonGetHelp
  } = useAppContext();
  const searchParams = useSearchParams();
  useEffect(() => {
    const navbar = document.getElementById("mobBackCta");

    // ticking variable to prevent scroll event handler from being invoked too often, which could cause performance issues.
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          const currentScrollPos = window.scrollY;
          if (navbar) {
            if (currentScrollPos < 1 || navbarBackButtonTitle === "Orders") {
              navbar.style.borderBottom = "none";
            } else {
              navbar.style.borderBottom = `1px solid ${theme.colors.stroke.divider.default}`;
            }
          }
          ticking = false;
        });
        ticking = true;
      }
    };
    if (showBorder) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBorder, navbarBackButtonTitle]);

  // till we refactor the states in app context
  const getBackIconClickId = () => {
    if (navbarBackButtonTitle === "Orders") {
      return ORDERS_PAGE_CLICK_IDS.header.pageBack;
    }
  };

  // till we refactor the states in app context
  const getHelpClickId = () => {
    if (navbarBackButtonTitle === "Orders") {
      const selectedOrderTab = String(getFromSessionStorage("SELECTED_ORDER_TAB"));
      return ORDERS_PAGE_CLICK_IDS.header.getHelp(selectedOrderTab);
    }
  };
  const handleBackNavigation = () => {
    if (ovverrideBackNavLink) {
      router.push(ovverrideBackNavLink);
      return;
    }
    if (isWebview() && window.Android) {
      window.Android.closeWebview();
    } else if (isIOSWebview()) {
      window.webkit.messageHandlers.closeWebViewIOS.postMessage(null);
    } else if (searchParams.get("from") === "pg-status") {
      window.location.href = SITE_URLS.BONDS_LISTING;
    } else if (path) {
      window.location.href = path;
    } else if (document.referrer && document.referrer !== window.location.href) {
      window.location.href = document.referrer;
    } else {
      window.location.href = `${window.location.origin.split("?")[0]}${getRedirectLinkBasedonInvestorStatus(SITE_URLS.FD_HOME, SITE_URLS.BONDS_HOME)}`;
    }
  };
  const handleHelpCta = () => {
    router.push(SITE_URLS.HELP);
  };
  return !isDesktop ? <MobNavbarContainer>
			<Wrapper $isFixed={isFixed} id="mobBackCta" $margin={margin} $padding="16px 24px">
				<Flex $gap="8px" $alignItems="center" id={getBackIconClickId()}>
					<motion.div whileTap={{
          scale: 0.9
        }} style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }} id={getBackIconClickId()}>
						<ArrowLeft24StrokedIcon color="#000" onClick={handleBackNavigation} id={getBackIconClickId()} />
					</motion.div>

					{!isUndefinedOrNull(navbarBackButtonTitle) && <BodyMediumText $mobColor={theme?.colors.text.secondary} onClick={handleBackNavigation} id={getBackIconClickId()}>
							{navbarBackButtonTitle}
						</BodyMediumText>}
				</Flex>
				{showNavbarBackButtonGetHelp && <LabelSemiBoldText $mobColor={theme.colors.text.tertiary} onClick={handleHelpCta} id={getHelpClickId()}>
						Get help
					</LabelSemiBoldText>}
			</Wrapper>
		</MobNavbarContainer> : null;
};
export default BackMobNavigation;
const MobNavbarContainer = styled(GridItemContainer)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 5;
	width: 100%;
	border-image-slice: 1;
	background-color: white;
`;
const Wrapper = styled.div<{
  $isFixed?: boolean;
  $margin?: string;
  $padding?: string;
}>`
	width: 100%;
	position: ${({
  $isFixed
}) => $isFixed ? "sticky" : ""};
	padding: ${({
  $padding
}) => $padding || ""};
	margin: ${({
  $margin
}) => $margin || ""};
	top: 0;
	background-color: ${({
  theme
}) => theme.colors.bg.section.default};
	z-index: 999999;

	${FlexRow}
	gap: 8px;
	justify-content: space-between;
	align-items: center;
`;