export const IS_REINVESTMENT_FLOW = "IS_REINVESTMENT_FLOW";
export const REINVESTMENT_PAGE_CLICK_IDS = {
  landing: {
    proceed: "REINVESTMENTS-LANDING-GET_HELP",
    noRepayments: "REINVESTMENTS-LANDING_NO_REPAYMENTS-EXPLORE_BONDS",
    alreadyReserved: "REINVESTMENTS-LANDING_ALREADY_RESERVED-VIEW_ORDERS",
    noActiveBonds: "REINVESTMENTS-LANDING_NO_ACTIVE_BONDS-HOME"
  },
  bondsListing: {
    repaymentBreakdown: "REINVESTMENTS-BONDS_LISTING-REPAYMENT_BREAKDOWN",
    assetCard: "REINVESTMENTS-BONDS_LISTING-ASSET_CARD"
  }
};