"use client";

import styled, { css } from "styled-components";
import { useAnimation, m, LazyMotion, domAnimation } from "framer-motion";
import { useEffect } from "react";
import { useAppContext } from "@common/context/AppContext";
import { largeDesktopCss, minDesktopCss } from "@common/styles/media";
import { backgroundPatternDesk, backgroundPatternMob } from "@features/Landing/constants/hero";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
import { useLandingContext } from "@features/Landing/contexts/index";
import { WintLogoDarkSymbol } from "@common/components/MainLogo";
import AnimatedTitle from "../AnimatedTitle/index";
import PulseLineLoader from "./pulseLoader";
const Hero = () => {
  const {
    landingStrapiData
  } = useLandingContext();
  const {
    heroSection
  } = landingStrapiData?.data?.[0]?.attributes ?? null;
  const {
    isMobile,
    isDesktop,
    setShowLoginModal
  } = useAppContext();
  const ctrls = useAnimation();
  useEffect(() => {
    ctrls.start("visible");
  }, [ctrls]);
  return <LazyMotion features={domAnimation} data-sentry-element="LazyMotion" data-sentry-component="Hero" data-sentry-source-file="index.tsx">
			<Wrapper data-sentry-element="Wrapper" data-sentry-source-file="index.tsx">
				<Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
					{isMobile && <Header>
							<WintLogoDarkSymbol />
							<HeaderText>{heroSection?.infoText}</HeaderText>
						</Header>}

					<Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
						{isDesktop ? <TitleContainer>
								{heroSection?.deskTitle?.map((data: any, index: number) => <AnimatedTitle key={index} textColor={data?.textColor} text={data?.title} fontWeight={index > 0 ? 600 : 400} duration={1.5} />)}
							</TitleContainer> : <TitleContainer transition={{
            delay: 2
          }}>
								{heroSection?.mobTitle?.map((data: any, index: number) => <AnimatedTitle key={index} textColor={data?.textColor} text={data?.title} fontWeight={index > 1 ? 600 : 400} fontSize="3rem" duration={1.5} />)}
							</TitleContainer>}

						<PulseLineLoader data-sentry-element="PulseLineLoader" data-sentry-source-file="index.tsx" />

						<SubTitleContainer data-sentry-element="SubTitleContainer" data-sentry-source-file="index.tsx">
							<m.div initial={{
              opacity: 0,
              y: 30
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 1,
              ease: "easeInOut",
              delay: 1.5
            }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								{isDesktop ? <SubTitle>{heroSection?.subTitle}</SubTitle> : <SubTitle>{heroSection?.subTitleMob}</SubTitle>}
							</m.div>
						</SubTitleContainer>
						{isDesktop && <ButtonContainer>
								<m.div initial={{
              opacity: 1,
              y: 60
            }} animate={{
              opacity: 1,
              y: 0
            }} transition={{
              duration: 1,
              ease: "easeInOut",
              delay: 2
            }}>
									<StyledButton id={LANDING_CLICK_IDS.hero_get_started} onClick={() => setShowLoginModal(true)}>
										Get Started
									</StyledButton>
								</m.div>
							</ButtonContainer>}
					</Content>
				</Container>
				<RiveContainer data-sentry-element="RiveContainer" data-sentry-source-file="index.tsx">
					<iframe title="mobile mock" style={{
          willChange: "transform"
        }} src={heroSection?.assetLink} width="100%" height="60%" frameBorder="0" />
				</RiveContainer>
			</Wrapper>
		</LazyMotion>;
};
export default Hero;
const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;
	margin-top: 0.75rem;
`;
const HeaderText = styled.p`
	color: #858585;
	text-align: center;
	font-family: var(--font-inter);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
`;
const Wrapper = styled.div`
	background-color: #040f1c;
	background-image: url(${backgroundPatternMob});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 600px;
	height: calc(100vh - 68px);
	overflow: hidden;
	position: relative;

	${minDesktopCss(css`
		background-image: url(${backgroundPatternDesk});
		min-height: 800px;
		height: 100vh;
		max-height: 1000px;
	`)}
`;
const Container = styled.div`
	flex-direction: column;
	width: 100%;
	padding: 24px 24px 10px 24px;
	gap: 12px;
	align-items: center;
	justify-content: center;
	display: flex;

	${minDesktopCss(css`
		padding: 100px 200px 0px 200px;
		max-width: 1500px;
	`)}

	${largeDesktopCss(css`
		padding: 140px 200px 0px 200px;
	`)}
`;
const TitleContainer = styled(m.h1)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	width: 100%;
`;
const SubTitleContainer = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	overflow: hidden;

	${minDesktopCss(css`
		height: 40px;
	`)}
`;
const SubTitle = styled(m.p)`
	color: rgba(255, 255, 255, 0.4);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 1.5rem;
	text-align: center;
	overflow: hidden;

	${minDesktopCss(css`
		font-weight: 400;
		margin-top: 1.25rem;
		text-align: left;
		font-size: 1rem;
	`)}
`;
const RiveContainer = styled.div`
	display: flex;
	align-items: flex-end;
	width: 90%;
	height: 60%;
	margin-top: 60px;
	position: absolute;
	bottom: 0;
	margin-right: 40px;

	${minDesktopCss(css`
		width: 350px;
		height: 65%;
		margin-top: 0;
		margin-right: 72px;
	`)}

	${largeDesktopCss(css`
		width: 800px;
		height: 65%;
		margin-right: 100px;
	`)}

	@media(min-width: 1900px) {
		width: 900px;
		height: 65%;
	}
`;
export const Character = styled(m.span)`
	display: inline-block;
`;
export const Word = styled(m.span)`
	display: inline-block;
	margin-right: 0.25em;
	white-space: nowrap;
`;
const ButtonContainer = styled.div`
	height: 80px;
	overflow: hidden;
	cursor: pointer;
	z-index: 999;
`;
const StyledButton = styled.button`
	display: flex;
	padding: 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	border: none;
	border-radius: 4px;
	background: #fff;
	color: var(--color-TEXT-button-onInverse, #000);
	text-align: center;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 150% */
	width: 184px;
	margin-top: 1rem;
	cursor: pointer;

	${minDesktopCss(css`
		font-size: 16px;
		margin-top: 1.5rem;
		line-height: 150%;
	`)}
`;