import { ReactNode } from "react";
import Label from "@wint_design_system/molecules/Label/Label";
import { RFQ_PAYMENT_STATUS } from "@common/constants/orders";
import { Close16StrokedIcon, Tick16StrokedIcon } from "wint-icons";
import { getImagesCdnUrl } from "@common/utils/env";
import Image from "next/image";
import { PrimaryText } from "../commonStyles";
export type OrderStatus = "APPROVED" | "OPENED" | "PENDING" | "PROGRESS" | "INITIATED" | "FAILED";
export const getLabelTags = (text: string | "", status: OrderStatus | string, inverseTag: boolean = false, theme: any = {}): ReactNode => {
  switch (status) {
    case "APPROVED":
    case "OPENED":
    case "INITIATED":
    case "SUCCESS":
      return <Label styles={{
        width: !inverseTag ? "158px" : ""
      }} text={text} bgColor={inverseTag ? theme.colors.bg.feedback.disabled : theme.colors.bg.feedback.success} mobTextColor={inverseTag ? theme.colors.text.feedback.successSubtle : theme.colors.text.primary} webTextColor={inverseTag ? theme.colors.text.feedback.successSubtle : theme.colors.text.primary} />;
    case "PENDING":
    case "PROGRESS":
      return <Label styles={{
        width: !inverseTag ? "158px" : ""
      }} text={text} bgColor={inverseTag ? theme.colors.bg.feedback.disabled : theme.colors.bg.feedback.warning} mobTextColor={inverseTag ? theme.colors.text.feedback.successSubtle : theme.colors.text.primary} webTextColor={inverseTag ? theme.colors.text.feedback.successSubtle : theme.colors.text.primary} />;
    case "FAILED":
    case "REJECTED":
      return <Label styles={{
        width: !inverseTag ? "158px" : ""
      }} text={text} bgColor={inverseTag ? theme.colors.bg.feedback.disabled : theme.colors.bg.feedback.error} mobTextColor={inverseTag ? theme.colors.text.feedback.errorSubtle : theme.colors.text.primary} webTextColor={inverseTag ? theme.colors.text.feedback.errorSubtle : theme.colors.text.primary} />;
    case "UPCOMING":
      return <Label styles={{
        width: "148px"
      }} text={text} bgColor={theme.colors.bg.feedbackInverse.info} mobTextColor={theme.colors.text.textInverse.primary} webTextColor={theme.colors.text.textInverse.primary} />;
    default:
      return null;
  }
};
export const getPGStatusData = (paymentStatus: string, settlementDate?: string, isSettledOrder?: boolean) => {
  switch (paymentStatus) {
    case RFQ_PAYMENT_STATUS.success:
      return {
        tag: isSettledOrder ? "Units allocated" : "Payment successful",
        caption: "We have initiated your order",
        ctaText: "View my orders",
        bannerText: <>
						On <PrimaryText>{settlementDate}</PrimaryText>, units will be
						transferred to your demat account.
					</>
      };
    case RFQ_PAYMENT_STATUS.failed:
      return {
        tag: isSettledOrder ? "Failed" : "Payment failed",
        caption: "We couldn't process your payment. Please try again in sometime.",
        ctaText: "Try Again",
        bannerText: "Any amount debited, will be refunded back to your account within 3-4 working days"
      };
    case RFQ_PAYMENT_STATUS.progress:
    case RFQ_PAYMENT_STATUS.pending:
      return {
        tag: "Payment in progress",
        caption: "It is taking a bit longer than usual. We will notify you as soon as we have an update.",
        bannerText: "In case of payment failure, any amount debited will be refunded back to your account within 3-4 working days.",
        ctaText: "View my orders"
      };
    default:
      return {
        title: "Payment in progress",
        caption: "It is taking a bit longer than usual. We will notify you as soon as we have an update.",
        bannerText: "In case of payment failure, any amount debited will be refunded back to your account within 3-4 working days.",
        ctaText: "View my orders"
      };
  }
};
export const replaceAsterisks = (inputString: string): string => {
  const updatedString = inputString?.replace(/\*/g, ""); // Use a regex with the global flag to replace all occurrences

  return `xx${updatedString}`;
};
export const ORDERS_PAGE_CLICK_IDS = {
  pageLoad: (product: string) => `ORDERS-${product}-TEXT-SECTION_LOAD`,
  // done

  ongoingCardClicks: {
    ongoingCardSuccess: (productId: number, product: string) => `ORDERS-${product}-ONGOING-CARD-SUCCESS-WIDGET_OPEN-${productId}`,
    ongoingCardProgress: (productId: number, product: string) => `ORDERS-${product}-ONGOING-CARD-PROGRESS-WIDGET_OPEN-${productId}`,
    ongoingCardFailed: (productId: number, product: string) => `ORDERS-${product}-ONGOING-CARD-FAILED-WIDGET_OPEN-${productId}`
  },
  retryButton: {
    failedRetry: (productId: number, product: string) => `ORDERS-${product}-ONGOING-CTA-FAILED-RETRY-${productId}`,
    failedRetryOrdersWidget: (productId: number, product: string) => `ORDERS-${product}-ONGOING-CTA-FAILED-RETRY_ORDERS_WIDGET-${productId}`
  },
  assetPageCta: {
    iconAssetDetailsLoad: (productId: number, product: string, listingPage: string, orderState: string) => `ORDERS-${product}-${orderState}-ICON-${listingPage}_DETAILS_PAGE_LOAD-${productId}`
  },
  viewMoreDetailsPopUp: (productId: number, product: string, orderState: string) => `ORDERS-${product}-${orderState}-DROPDOWN-ORDER_DETAILS-${productId}`,
  contact: {
    desk: (product: string) => `ORDERS-${product}-TEXT-CX`,
    // done
    mob: (product: string) => `ORDERS-${product}-CTA-CX` // done
  },
  navigation: {
    arrowBackToOrders: (product: string) => `ORDERS-${product}-ARROW-BACK_TO_ORDERS` // done
  },
  closeFilterMenu: (product: string) => `ORDERS-${product}-ALL-ICON-TYPE_OF_ORDERS_CROSS`,
  // done

  filterIds: {
    settledDropdownOrders: (product: string) => `ORDERS-${product}-ALL-TEXT-SETTLED_DROPDOWN_ORDERS`,
    failedDropdownOrders: (product: string) => `ORDERS-${product}-ALL-TEXT-FAILED_DROPDOWN_ORDERS`
  },
  settledCardClicks: {
    settledCardWidgetOpen: (productId: number, product: string) => `ORDERS-${product}-ALL-CARD-SETTLED-WIDGET_OPEN-${productId}`,
    failedCardWidgetOpen: (productId: number, product: string) => `ORDERS-${product}-ALL-CARD-FAILED-WIDGET_OPEN-${productId}`
  },
  viewMore: (product: string) => `ORDERS-${product}-ALL-TEXT-VIEW_MORE`,
  // done

  noInvestmentCta: (product: string) => `ORDERS-${product}-CTA-INVESTMENT_START`,
  // done
  copyCta: {
    fd: {
      orderIdCopy: (productId: number) => `ORDERS-FD-ICON-ORDER_ID_COPY-${productId}`
    },
    bonds: {
      orderIdCopy: (productId: number) => `ORDERS-BONDS-ICON--ORDER_ID_COPY-${productId}`,
      dealIdCopy: (productId: number) => `ORDERS-BONDS-ICON-DEAL_ID_COPY-${productId}`,
      isinCopy: (productId: number) => `ORDERS-BONDS-ICON-ISIN_COPY-${productId}`
    }
  },
  header: {
    pageBack: "ORDERS-ARROW-BACK",
    getHelp: (product: string) => `ORDERS-${product}-TEXT-CX_TOP_RIGHT`
  },
  menuClick: (product: string) => `ORDERS-${product}-ALL-DROPDOWN-TYPE_OF_ORDERS`
};
export const getProductName = (key: number): string => {
  if (key === 0) {
    return "BONDS";
  }
  return "FDS";
};

/**
 *
 * @param fillColor
 * @returns icon in red for failed state
 * @returns icon in orange for pending state
 */
const renderFailedOrProgressSVG = (fillColor: string) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" data-sentry-element="svg" data-sentry-component="renderFailedOrProgressSVG" data-sentry-source-file="index.tsx">
			<g clipPath="url(#clip0_1628_5105)" data-sentry-element="g" data-sentry-source-file="index.tsx">
				<path d="M10.0008 11.4857C10.2641 11.4857 10.5167 11.3811 10.7029 11.1949C10.889 11.0087 10.9937 10.7562 10.9937 10.4928V6.53581C10.9937 6.27249 10.889 6.01996 10.7029 5.83376C10.5167 5.64757 10.2641 5.54297 10.0008 5.54297C9.7375 5.54297 9.48496 5.64757 9.29877 5.83376C9.11258 6.01996 9.00798 6.27249 9.00798 6.53581V10.4928C9.00798 10.7562 9.11258 11.0087 9.29877 11.1949C9.48496 11.3811 9.7375 11.4857 10.0008 11.4857ZM10.0008 14.457C10.2463 14.457 10.4862 14.3842 10.6903 14.2479C10.8944 14.1115 11.0535 13.9177 11.1474 13.6909C11.2413 13.4641 11.2659 13.2146 11.218 12.9739C11.1701 12.7331 11.0519 12.512 10.8784 12.3384C10.7048 12.1649 10.4837 12.0467 10.2429 11.9988C10.0022 11.9509 9.75266 11.9755 9.52588 12.0694C9.29911 12.1633 9.10529 12.3224 8.96892 12.5265C8.83255 12.7306 8.75977 12.9705 8.75977 13.216C8.75977 13.5451 8.89052 13.8608 9.12326 14.0935C9.356 14.3263 9.67167 14.457 10.0008 14.457Z" fill={fillColor} data-sentry-element="path" data-sentry-source-file="index.tsx" />
			</g>
			<defs data-sentry-element="defs" data-sentry-source-file="index.tsx">
				<clipPath id="clip0_1628_5105" data-sentry-element="clipPath" data-sentry-source-file="index.tsx">
					<rect width="16" height="16" fill="white" data-sentry-element="rect" data-sentry-source-file="index.tsx" />
				</clipPath>
			</defs>
		</svg>;
};
export const getStepperStateIcon = (status: string, index: number, theme: any, isOngoingOrder: boolean) => {
  const statusIcon = <Tick16StrokedIcon color={theme.colors.text.textInverse.primary} />;
  const pendingOrOngoingFailedIcon = renderFailedOrProgressSVG(theme.colors.text.textInverse.primary);
  const settleFailedIcon = <Close16StrokedIcon color={theme.colors.text.textInverse.primary} />;
  if (status === RFQ_PAYMENT_STATUS.success) {
    return statusIcon;
  }
  switch (index) {
    case 0:
      if ([RFQ_PAYMENT_STATUS.pending, RFQ_PAYMENT_STATUS.progress, RFQ_PAYMENT_STATUS.failed].includes(status)) {
        if (status === RFQ_PAYMENT_STATUS.failed) {
          if (!isOngoingOrder) {
            return settleFailedIcon;
          }
          return pendingOrOngoingFailedIcon;
        }
        return pendingOrOngoingFailedIcon;
      }
      break;
    case 1:
    case 2:
      if (status === RFQ_PAYMENT_STATUS.failed && (index === 1 || !isOngoingOrder)) {
        return <Close16StrokedIcon color="#FFC7C5" />;
      }
      if (status === RFQ_PAYMENT_STATUS.failed) {
        return pendingOrOngoingFailedIcon;
      }
      return <Image src={`${getImagesCdnUrl()}/Bonds/${index === 1 ? "pendingOrderIcon" : "timeIcon"}.svg`} width={16} height={16} alt="stepper-icon" />;
    default:
      return null;
  }
};
export const getIconBgAndLineColor = (status: string, index: number, theme: any) => {
  if (status === RFQ_PAYMENT_STATUS.success) {
    return {
      iconBg: theme.colors.icon.accent.green,
      lineColor: "#8BF1A7" // --color-STROKE-Feedback-Inverse-Inverse---Success not in our DS
    };
  }
  if ((status === RFQ_PAYMENT_STATUS.progress || status === RFQ_PAYMENT_STATUS.pending) && index === 0) {
    return {
      iconBg: "#F0B944",
      // custom color for this, not in DS
      lineColor: theme.colors.stroke.feedback.default
    };
  }
  if (status === RFQ_PAYMENT_STATUS.failed) {
    return {
      iconBg: theme.colors.bg.feedbackInverse.error,
      lineColor: theme.colors.stroke.feedback.default
    };
  }
  return {
    iconBg: theme.colors.bg.section.emphasized,
    lineColor: theme.colors.stroke.feedback.default
  };
};
export const ORDERS_SORT_KEYS = (settledOrders: any, selectedTab: number) => [{
  key: "1",
  label: `Settled Orders (${settledOrders.SUCCESS?.length})`,
  value: "SUCCESS",
  isDisabled: !settledOrders.SUCCESS?.length,
  id: ORDERS_PAGE_CLICK_IDS.filterIds.settledDropdownOrders(getProductName(selectedTab))
}, {
  key: "2",
  label: `Failed Orders (${settledOrders.FAILED?.length})`,
  value: "FAILED",
  isDisabled: !settledOrders.FAILED?.length,
  id: ORDERS_PAGE_CLICK_IDS.filterIds.failedDropdownOrders(getProductName(selectedTab))
}];