import { getImagesCdnUrl } from "@common/utils/env"

const IMAGE_CDN = getImagesCdnUrl()

export const WINT_LOGO = {
    WINT_LOGO_LIGHT: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoLight.svg`,
    WINT_LOGO_DARK: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoDark.svg`,
    WINT_LOGO_LIGHT_MONO: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoLightMono.svg`,
    WINT_LOGO_DARK_MONO: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoDarkMono.svg`,
    WINT_LOGO_DARK_SYMBOL: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoSymbolDark.svg`,
    WINT_LOGO_LIGHT_SYMBOL: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoSymbolLight.svg`,
    WINT_LOGO_BLACK_SYMBOL: `${IMAGE_CDN}/webDS/icons/mainLogo/WintLogoSymbolBlack.svg`,
}
