import { useAppContext } from "@common/context/AppContext";
import { Flex } from "@common/styles";
import { minDesktopCss } from "@common/styles/media";
import { ButtonDefaultText, HeadingSerifLargeText } from "@common/styles/typographies";
import { getImagesCdnUrl } from "@common/utils/env";
import Button from "@wint_design_system/molecules/Buttons/Button";
import Image from "next/image";
import styled, { ThemeProvider, css, useTheme } from "styled-components";
import { light } from "@common/styles/semanticColors";
import { SITE_URLS } from "@common/constants";
type GlobalErrorProps = {
  title?: string;
  description?: string;
  ctaText?: string;
  onClick?: () => void;
  clickId?: string;
  illustration?: string;
};
const GlobalError = ({
  title = "We’re facing some technical issue.",
  description = "We’ve run into a small technical issue while loading the page. Our team is already alerted and fixing this. Kindly check the page after 10-15 minutes.",
  ctaText = "Explore Assets",
  onClick = () => {
    // @TODO: Add a mixpanel or clevertap event to inform system about some issue ocurred with details
    window.location.href = SITE_URLS.BONDS_LISTING;
  },
  clickId = "",
  illustration = `${getImagesCdnUrl()}/common/globalErrorScreenV2.svg`
}: GlobalErrorProps) => {
  const {
    isDesktop
  } = useAppContext();
  const theme = useTheme();
  return <ThemeProvider theme={light} data-sentry-element="ThemeProvider" data-sentry-component="GlobalError" data-sentry-source-file="GlobalError.tsx">
			<ErrorWrap data-sentry-element="ErrorWrap" data-sentry-source-file="GlobalError.tsx">
				<ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="GlobalError.tsx">
					<Image src={illustration} fill alt="page_break" draggable={false} data-sentry-element="Image" data-sentry-source-file="GlobalError.tsx" />
				</ImageContainer>
				<Flex $direction="column" $gap={isDesktop ? "4px" : "8px"} data-sentry-element="Flex" data-sentry-source-file="GlobalError.tsx">
					<HeadingSerifLargeText data-sentry-element="HeadingSerifLargeText" data-sentry-source-file="GlobalError.tsx">{title}</HeadingSerifLargeText>
					<Description data-sentry-element="Description" data-sentry-source-file="GlobalError.tsx">{description} </Description>
				</Flex>
				<Button clickId={clickId} onClick={onClick} text={<ButtonDefaultText $webColor={theme?.colors.text.button.onPrimary} $mobColor={theme?.colors.text.button.onPrimary}>
							{ctaText}
						</ButtonDefaultText>} size={isDesktop ? "large" : "medium"} mobileStyles={{
        padding: "1rem"
      }} type="primary" data-sentry-element="Button" data-sentry-source-file="GlobalError.tsx" />
			</ErrorWrap>
		</ThemeProvider>;
};
export default GlobalError;
const ErrorWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 24px;
	padding: 0 24px;
	button {
		width: 100%;
		height: 56px;

		${minDesktopCss(css`
			width: 219px;
			height: 56px;
		`)}
		color: ${props => props.theme.colors.text.button.onPrimary};
	}
`;
const Description = styled.div`
	text-align: center;

	color: ${props => props.theme.colors.text.secondary};
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	opacity: 0.7
`;
const ImageContainer = styled.div`
	width: 100%;
	height: 312px;
	margin-top: 64px;
	${minDesktopCss(css`
		width: 488px;
		height: 366px;
		margin-top: 40px;
	`)}

	img {
		object-fit: contain;
		position: relative !important;
	}
`;