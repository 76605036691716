"use client";

import { useRouter } from "next/navigation";
import styled, { css, useTheme } from "styled-components";
import { SITE_URLS } from "@common/constants";
import { useAppContext } from "@common/context/AppContext";
import { minDesktopCss } from "@common/styles/media";
import { ButtonDefaultText } from "@common/styles/typographies";
import Button from "@wint_design_system/molecules/Buttons/Button";
type StabilityBannerProps = {
  sectionTitle?: string;
  subTitle?: string;
  ctaText?: string;
  ctaId?: string;
};
const StabilityBanner = ({
  sectionTitle = "It’s time to add <span>stability</span> to your portfolio",
  subTitle = "Without compromising on high returns.",
  ctaText = "Get Started",
  ctaId
}: StabilityBannerProps) => {
  const router = useRouter();
  const {
    setShowLoginModal,
    userState
  } = useAppContext();
  const theme = useTheme();
  const onCTAClick = () => {
    if (userState.isLoggedIn) {
      router.push(SITE_URLS.BONDS_HOME);
    } else {
      setShowLoginModal(true);
    }
  };
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="StabilityBanner" data-sentry-source-file="stabilityBanner.tsx">
			<Title dangerouslySetInnerHTML={{
      __html: userState.isLoggedIn ? "Invest in bonds with <span>9-12% fixed returns</span>" : sectionTitle
    }} data-sentry-element="Title" data-sentry-source-file="stabilityBanner.tsx" />
			<SubText data-sentry-element="SubText" data-sentry-source-file="stabilityBanner.tsx">
				{userState.isLoggedIn ? "Start with just ₹10,000" : subTitle}
			</SubText>

			<Button clickId={ctaId} type="primary" text={<ButtonDefaultText id={ctaId} $webColor={theme.colors.text.button.onInverse} $mobColor={theme.colors.text.button.onInverse}>
						{userState.isLoggedIn ? "Explore bonds" : ctaText}
					</ButtonDefaultText>} themeInverse size="large" desktopStyles={{
      width: "217px",
      marginTop: "40px"
    }} mobileStyles={{
      marginTop: "32px"
    }} onClick={onCTAClick} data-sentry-element="Button" data-sentry-source-file="stabilityBanner.tsx" />
		</Wrapper>;
};
export default StabilityBanner;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
	background: #101010;
	padding: 88px 24px;

	${minDesktopCss(css`
		padding: 160px 24px;
	`)}
`;
const Title = styled.p`
	color: rgba(255, 255, 255, 0.4);
	text-align: center;
	font-family: var(--font-bricolage-grotesque);
	font-size: 2rem;
	font-style: normal;
	font-weight: 600;

	span {
		color: ${props => props.theme.colors.text.textInverse.primary};
	}

	${minDesktopCss(css`
		font-size: 56px;
		max-width: 800px;
	`)}
`;
const SubText = styled.p`
	color: ${props => props.theme.colors.text.tertiary};
	text-align: center;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-top: 8px;

	${minDesktopCss(css`
		font-size: 1rem;
	`)}
`;