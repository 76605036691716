"use client";

import styled, { css, useTheme } from "styled-components";
import { minDesktopCss } from "@common/styles/media";
import Button from "@wint_design_system/molecules/Buttons/Button";
import { ButtonDefaultText } from "@common/styles/typographies";
import useScrollDirection from "@common/hooks/useScrollDirection";
import { useAppContext } from "@common/context/AppContext";
import { LANDING_CLICK_IDS } from "@features/Landing/constants/clickIDS";
import { WintLogoDark, WintLogoLight } from "@common/components/MainLogo";
type NavThemeType = {
  backgroundColor: string;
  textColor: string;
  underLine: string;
};
export const navTheme: Record<string, NavThemeType> = {
  transparent: {
    backgroundColor: "transparent",
    textColor: "#fff",
    underLine: "#fff"
  },
  light: {
    backgroundColor: "#fff",
    textColor: "#101010",
    underLine: "#3de86d"
  },
  dark: {
    backgroundColor: "#000",
    textColor: "#fff",
    underLine: "#fff"
  },
  blue: {
    backgroundColor: "#040F1C",
    textColor: "#fff",
    underLine: "#fff"
  }
};
type LandingNavProps = {
  navbarTheme?: string;
};
const LandingNav: React.FC<LandingNavProps> = ({
  navbarTheme = "transparent"
}) => {
  const {
    scrollDirection
  } = useScrollDirection();
  const {
    setShowLoginModal
  } = useAppContext();
  const theme = useTheme();
  return <Wrapper $backgroundColor={navTheme[navbarTheme].backgroundColor} $scrollDirection={scrollDirection} data-sentry-element="Wrapper" data-sentry-component="LandingNav" data-sentry-source-file="index.tsx">
			<Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
				<Left data-sentry-element="Left" data-sentry-source-file="index.tsx">
					{navbarTheme === "light" ? <WintLogoLight /> : <WintLogoDark />}
				</Left>
				<Right data-sentry-element="Right" data-sentry-source-file="index.tsx">
					<Button clickId={LANDING_CLICK_IDS.nav_login} type="primary" text={<StyledButtonDefaultText id={LANDING_CLICK_IDS.nav_login} $webColor={navbarTheme !== "light" ? theme.colors.text.button.onInverse : theme.colors.text.button.onPrimary}>
								Get Started
							</StyledButtonDefaultText>} onClick={() => setShowLoginModal(true)} size="medium" themeInverse={navbarTheme === "dark" || navbarTheme === "transparent" || navbarTheme === "blue"} desktopStyles={{
          padding: "5px 14px",
          borderRadius: "none"
        }} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
				</Right>
			</Container>
		</Wrapper>;
};
export default LandingNav;
type WrapperProps = {
  $scrollDirection?: string;
  $backgroundColor?: string;
};
const Wrapper = styled.div<WrapperProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({
  $backgroundColor
}) => $backgroundColor !== "transparent" ? $backgroundColor : "transparent"};
	width: 100%;
	position: fixed;
	top: 0;
	transition: all 0.2s ease-in-out;
	z-index: 999;
`;
const Container = styled.div<WrapperProps>`
	display: none;

	${minDesktopCss(css<WrapperProps>`
		display: flex;
		max-width: 1500px;
		padding: 16px 200px;
		justify-content: space-between;
		width: 100%;
		height: 64px;
	`)}
`;
const Left = styled.div`
	display: flex;
	gap: 56px;
	align-items: center;
`;
const Right = styled.div`
	display: flex;
`;
const StyledButtonDefaultText = styled(ButtonDefaultText)`
	font-size: 14px;
	font-weight: 500;
`;