"use client";

import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type CleverTap from "clevertap-web-sdk/clevertap";
import { usePathname } from "next/navigation";
import { USER_OBJ_COOKIE_KEY } from "@common/constants/storage_keys";
import { breakpoints } from "@common/theme";
import { User, UserFeatureFlags } from "@common/types/user";
import { defaultUserState, getUser } from "@common/utils/auth";
import { getFromSessionStorage, getIsIOSDeviceByUserAgent, isBrowser, setCookie, setToSessionStorage } from "@common/utils/browser";
import { getCleverTapProjectId } from "@common/utils/env";
import { IS_REINVESTMENT_FLOW } from "@features/ReInvestments/constants";
const isBrowserWindow = isBrowser();
interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}
interface AppContextLayout {
  userState: User;
  setUserState: Dispatch<SetStateAction<User>>;
  windowSize: WindowSize | undefined;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isBrowserWindow: boolean;
  isPageSummaryApiLoading: boolean;
  setIsPageSummaryApiLoading: Function;
  isIOSDevice: boolean;
  clevertapModule: CleverTap | undefined;
  showLoginModal: boolean;
  setShowLoginModal: Function;
  referrerName: string;
  setReferrerName: Function;
  referrerType: string;
  setReferrerType: Function;
  ovverrideBackNavLink: string | undefined;
  setOverrideBackNavLink: Dispatch<SetStateAction<string | undefined>>;
  navbarBackButtonTitle: string | undefined;
  setNavbarBackButtonTitle: Dispatch<SetStateAction<string | undefined>>;
  showNavbarBackButtonGetHelp: boolean | undefined;
  setShowNavbarBackButtonGetHelp: Dispatch<SetStateAction<boolean>>;
  userFeatureFlags: UserFeatureFlags;
  setUserFeatureFlags: Dispatch<SetStateAction<UserFeatureFlags>>;
}
interface AppContextProviderProps {
  children: React.ReactNode;
  serverSideIsMobile: boolean;
  serverSideIsTablet: boolean;
  serverSideIsDesktop: boolean;
  serverSideIsIOSDevice: boolean;
  serverSideUserObj: User;
}
const defaultContextLayout: AppContextLayout = {
  userState: defaultUserState,
  setUserState: () => {},
  isBrowserWindow,
  windowSize: isBrowserWindow ? {
    width: window.innerWidth,
    height: window.innerHeight
  } : undefined,
  isMobile: isBrowserWindow && window.innerWidth <= breakpoints.mobile.max,
  isTablet: isBrowserWindow && window.innerWidth <= breakpoints.tablet.max,
  isDesktop: isBrowserWindow && window.innerWidth >= breakpoints.desktop.min,
  isPageSummaryApiLoading: true,
  setIsPageSummaryApiLoading: () => {},
  isIOSDevice: false,
  clevertapModule: undefined,
  showLoginModal: false,
  setShowLoginModal: () => {},
  referrerName: "",
  setReferrerName: () => {},
  referrerType: "",
  setReferrerType: () => {},
  ovverrideBackNavLink: undefined,
  setOverrideBackNavLink: () => {},
  navbarBackButtonTitle: undefined,
  setNavbarBackButtonTitle: () => {},
  showNavbarBackButtonGetHelp: false,
  setShowNavbarBackButtonGetHelp: () => {},
  userFeatureFlags: undefined,
  setUserFeatureFlags: () => {}
};
const AppContext = createContext<AppContextLayout>(defaultContextLayout);
export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("AppContext must be used inside <AppContextProvider /> ");
  }
  return appContext;
};
export const AppContextProvider = ({
  children,
  serverSideIsMobile = false,
  serverSideIsTablet = false,
  serverSideIsDesktop = false,
  serverSideIsIOSDevice = false,
  serverSideUserObj = defaultUserState
}: AppContextProviderProps) => {
  const pathName = usePathname();
  const initialUserObj = !isBrowserWindow ? serverSideUserObj : getUser();
  const [userState, setUserState] = useState<User>(initialUserObj);
  const [userFeatureFlags, setUserFeatureFlags] = useState<UserFeatureFlags>();
  const initialWindowSize = useMemo(() => isBrowserWindow ? {
    width: window.innerWidth,
    height: window.innerHeight
  } : undefined, []);
  const [windowSize, setWindowSize] = useState<WindowSize | undefined>(initialWindowSize);
  const [isMobile, setIsMobile] = useState(!isBrowserWindow ? serverSideIsMobile : window.innerWidth <= breakpoints.mobile.max);
  const [isTablet, setIsTablet] = useState(!isBrowserWindow ? serverSideIsTablet : window.innerWidth <= breakpoints.tablet.max);
  const [isDesktop, setIsDesktop] = useState(!isBrowserWindow ? serverSideIsDesktop : window.innerWidth >= breakpoints.tabletLandscape.min);
  const isIOSDevice = useMemo(() => !isBrowserWindow ? serverSideIsIOSDevice : getIsIOSDeviceByUserAgent(window?.navigator?.userAgent), [serverSideIsIOSDevice]);
  const [isPageSummaryApiLoading, setIsPageSummaryApiLoading] = useState<boolean>(true);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [referrerName, setReferrerName] = useState<string>("");
  const [referrerType, setReferrerType] = useState<string>("");
  const [clevertapModule, setClevertapModule] = useState<CleverTap>();
  const [ovverrideBackNavLink, setOverrideBackNavLink] = useState<string>();
  const [navbarBackButtonTitle, setNavbarBackButtonTitle] = useState<string>();
  const [showNavbarBackButtonGetHelp, setShowNavbarBackButtonGetHelp] = useState<boolean>(false);
  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    setIsMobile(window.innerWidth <= breakpoints.mobile.max);
    setIsTablet(window.innerWidth <= breakpoints.tablet.max);

    // setting this to tabletLandscape because we render conditinally render "desktop components" from there -- update in media query functions as well
    setIsDesktop(window.innerWidth >= breakpoints.tabletLandscape.min);
  }, []);
  const initializeAndRunClevertap = useCallback(async () => {
    if (!clevertapModule) {
      const clevertapModuleTemp = await import("clevertap-web-sdk");
      clevertapModuleTemp.default.init(getCleverTapProjectId() as string, "in1");
      clevertapModuleTemp.default.privacy.push({
        optOut: false
      });
      clevertapModuleTemp.default.privacy.push({
        useIP: false
      });
      clevertapModuleTemp.default.spa = true;
      setClevertapModule(clevertapModuleTemp.default);
    } else {
      clevertapModule.onUserLogin?.push({
        Site: {
          Name: userState.userName,
          Identity: userState.userId?.toString(),
          Email: userState.userEmail
        }
      });
    }
  }, [clevertapModule, userState.userEmail, userState.userId, userState.userName]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  // resetting reinvestment flow on route change from details/reinvestment page
  useEffect(() => {
    if (getFromSessionStorage(IS_REINVESTMENT_FLOW)) {
      if (!pathName.includes("reinvestments")) {
        setToSessionStorage(IS_REINVESTMENT_FLOW, false);
      }
    }
  }, [pathName]);
  useEffect(() => {
    if (JSON.stringify(serverSideUserObj) !== JSON.stringify(userState)) {
      setCookie(USER_OBJ_COOKIE_KEY, JSON.stringify(userState));
    }
  }, [serverSideUserObj, userState]);
  useEffect(() => {
    initializeAndRunClevertap();
  }, [initializeAndRunClevertap]);
  const value = useMemo(() => ({
    isBrowserWindow,
    userState,
    setUserState,
    windowSize,
    isMobile,
    isTablet,
    isDesktop,
    isPageSummaryApiLoading,
    setIsPageSummaryApiLoading,
    isIOSDevice,
    clevertapModule,
    showLoginModal,
    setShowLoginModal,
    referrerName,
    setReferrerName,
    referrerType,
    setReferrerType,
    ovverrideBackNavLink,
    setOverrideBackNavLink,
    navbarBackButtonTitle,
    setNavbarBackButtonTitle,
    userFeatureFlags,
    setUserFeatureFlags,
    showNavbarBackButtonGetHelp,
    setShowNavbarBackButtonGetHelp
  }), [userState, windowSize, isMobile, isTablet, isDesktop, isPageSummaryApiLoading, isIOSDevice, clevertapModule, showLoginModal, referrerName, referrerType, ovverrideBackNavLink, navbarBackButtonTitle, showNavbarBackButtonGetHelp, userFeatureFlags]);
  return <AppContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AppContextProvider" data-sentry-source-file="AppContext.tsx">{children}</AppContext.Provider>;
};