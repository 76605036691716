import { getUser } from "../auth/index"
import { isWebview } from "../browser/index"
import { concatStrings } from "../string/index"

interface EventAttributes {
	[key: string]: any
	userId?: string | null | number
	userAgent?: string
	kycStatus?: string
	isBondInvestor?: boolean
	isFDInvestor?: boolean
	isSGBInvestor?: boolean
	userEmail?: string
	userPhoneNumber?: number | string
	// fb events name convention
	em?: string
	ph?: number | string
	fn?: string
	ln?: string
}

const trackEventOnGTM = (
	eventName: string,
	eventAttributes?: EventAttributes,
) => {
	window.gtmDataLayer = window.gtmDataLayer || []
	const tempEvents = {
		userId: null,
		...eventAttributes,
	}

	if (isWebview()) {
		tempEvents.userAgent = "android"
	}

	const user = getUser()
	if (user) {
		if (user.userId) {
			tempEvents.userId = user.userId
		}

		if (user.kycStatus) {
			tempEvents.kycStatus = user.kycStatus
		}

		if (user.isBondsInvestor) {
			tempEvents.isBondInvestor = user.isBondsInvestor
		}

		if (user.isFDInvestor) {
			tempEvents.isFDInvestor = user.isFDInvestor
		}

		if (user.isSgbInvestor) {
			tempEvents.isSGBInvestor = user.isSgbInvestor
		}

		if (user.userEmail) {
			tempEvents.userEmail = user.userEmail
		}

		if (user.userPhoneNumber) {
			tempEvents.userPhoneNumber = concatStrings(
				"+91",
				user.userPhoneNumber.toString() || "",
			)
		}

		if (user.userEmail) {
			tempEvents.em = user.userEmail
		}

		if (user.userPhoneNumber) {
			tempEvents.ph = concatStrings(
				"+91",
				user.userPhoneNumber.toString() || "",
			)
		}

		if (user.userName) {
			tempEvents.fn = user.userName.split(" ")[0] || ""
			tempEvents.ln = user.userName.split(" ")[1] || ""
		}



	}

	window.gtmDataLayer.push({
		event: eventName,
		...tempEvents,
	})
}

export const trackEvent = (
	eventName: string,
	eventAttributes?: EventAttributes,
) => trackEventOnGTM(eventName, eventAttributes)
